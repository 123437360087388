import thumbnail3d from '../../../../ts/3d/services/thumbnail3d';
import thumbnails3dModule from '../thumbnails3dModule';

function thumbnail3dService($q) {
  'ngInject';

  const getThumbnail3dCanvas = (targetId, container, useDynamicLighting) => {
    return $q((resolve, reject) => {
      thumbnail3d
        .getThumbnail3dJson(targetId)
        .then(({ json, performance }) => {
          thumbnail3d
            .loadObjAndMtl3D(targetId, container, json, useDynamicLighting)
            .then(([canvas]) => resolve({ canvas, performance }));
        })
        .catch(reject);
    });
  };
  return {
    getThumbnail3dCanvas
  };
}

thumbnails3dModule.factory('thumbnail3dService', thumbnail3dService);

export default thumbnail3dService;
