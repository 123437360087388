import THREE, {
  DomElement,
  WebGLScene,
  WebGLCamera,
  WebGLRenderer,
  WebGLJson,
  WebGLMTLs,
  WebGLOrbitControls
} from 'THREE';
import TWEEN from 'TWEEN';
import {
  ThumbnailRenderers,
  MaxRenderDistanceDefault,
  MinRenderDistanceDefault
} from '../constants/thumbnail3dConstant';
import thumbnailUntil from './thumbnail3dUtil';

const { addLightsToScene } = thumbnailUntil;

const renderers: ThumbnailRenderers = {};

// Some weird dependency system problems need to be bypassed
const THREE = (window.THREE as unknown) as THREE;

const getRenderer = (key: string, container: DomElement) => {
  if (!renderers[key]) {
    renderers[key] = {
      container,
      renderer: new THREE.WebGLRenderer({ antialias: true, alpha: true })
    };
  }
  return renderers[key].renderer;
};

const containerWidth = (container: DomElement) => {
  return container.parentElement?.offsetWidth;
};

const containerHeight = (container: DomElement) => {
  return container.parentElement?.offsetHeight;
};

const render = (renderer: WebGLRenderer, scene: WebGLScene, camera: WebGLCamera) => {
  renderer.render(scene, camera);
};

const initializeControls = (
  renderer: WebGLRenderer,
  scene: WebGLScene,
  camera: WebGLCamera,
  container: DomElement,
  json: WebGLJson
): [WebGLOrbitControls, () => void] => {
  // The controller that lets us spin the camera around an object
  const orbitControls = new THREE.OrbitControls(camera, container, json, 'static');

  orbitControls.rotateSpeed = 1.5;
  orbitControls.zoomSpeed = 1.5;
  orbitControls.dampingFactor = 0.3;
  const onChange = () => {
    render(renderer, scene, camera);
  };
  orbitControls.addEventListener('change', onChange);

  const cleanUpOrbitControls = () => {
    orbitControls.removeEventListener('change', onChange);
    orbitControls.dispose();
  };

  return [orbitControls, cleanUpOrbitControls];
};

const animate = (
  controls: WebGLOrbitControls,
  renderer: WebGLRenderer,
  scene: WebGLScene,
  camera: WebGLCamera
) => {
  if (controls.enabled) {
    controls.update();
  }

  TWEEN.update();
  render(renderer, scene, camera);
  requestAnimationFrame(() => animate(controls, renderer, scene, camera));
};

const createCanvas = (
  renderer: WebGLRenderer,
  container: DomElement,
  camera: WebGLCamera
): [DomElement, () => void] => {
  const setRendererSize = () => {
    camera.aspect = containerWidth(container) / containerHeight(container);
    camera.updateProjectionMatrix();
    renderer.setSize(containerWidth(container), containerHeight(container));
  };

  renderer.setSize(containerWidth(container), containerHeight(container));
  const canvas = renderer.domElement;
  let resizeTimer = 0;
  const onWindowResize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(setRendererSize, 100);
  };
  const onWindowBeforeUnload = () => {
    // canvas goes black when navigating to another page
    canvas.style.display = 'none';
  };
  const cleanUpCanvas = () => {
    window.removeEventListener('resize', onWindowResize);
    window.removeEventListener('beforeunload', onWindowBeforeUnload);
  };
  window.addEventListener('resize', onWindowResize);
  window.addEventListener('beforeunload', onWindowBeforeUnload);
  return [canvas, cleanUpCanvas];
};

const loadObjAndMtl3D = (
  targetId: number,
  container: DomElement,
  json: WebGLJson,
  useDynamicLighting: boolean
) => {
  const rendererKey = `THREE_renderer_targetId_${targetId}`;
  const renderer = getRenderer(rendererKey, container);

  const calculatedMaxRenderDistance =
    new THREE.Vector3(json.aabb.max.x, json.aabb.max.y, json.aabb.max.z).length() * 4;
  const maxRenderDistance = Math.max(calculatedMaxRenderDistance, MaxRenderDistanceDefault);

  const fieldOfView = typeof json.camera.fov !== 'undefined' ? json.camera.fov : 70;

  const camera = new THREE.PerspectiveCamera(
    fieldOfView,
    containerWidth(container) / containerHeight(container),
    MinRenderDistanceDefault,
    maxRenderDistance
  );

  const scene = new THREE.Scene();

  let controls: WebGLOrbitControls;

  const mtlLoader = new THREE.MTLLoader();
  const objLoader = new THREE.OBJLoader();

  return new Promise((resolve, reject) => {
    const objAndMtlLoaded = (modelObject: object) => {
      addLightsToScene(scene, camera, useDynamicLighting);
      scene.add(camera);
      scene.add(modelObject);

      const [canvas, cleanUpCanvas] = createCanvas(renderer, container, camera);

      const [orbitControls, cleanUpOrbitControls] = initializeControls(
        renderer,
        scene,
        camera,
        container,
        json
      );
      controls = orbitControls;
      render(renderer, scene, camera);
      animate(controls, renderer, scene, camera);

      const cleanUpObjects = () => {
        cleanUpCanvas();
        cleanUpOrbitControls();
      };

      resolve([canvas, cleanUpObjects]);
    };

    mtlLoader.load(
      json.mtl,
      (materials: WebGLMTLs) => {
        materials.preload();
        objLoader.setMaterials(materials).load(json.obj, objAndMtlLoaded, undefined, reject);
      },
      undefined,
      reject
    );
  });
};

export default {
  loadObjAndMtl3D
};
