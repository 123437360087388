import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import thumbnail3d from '../../../ts/3d/services/thumbnail3d';
import { ThumbnailTypes } from '../../../ts/3d/constants/thumbnail3dConstant';

function ThumbnailAnimationContainer({
  targetId,
  getThumbnailJson,
  onSuccess,
  onFailure,
  onAnimationLoopFinish,
  loadingClass
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [animationRequestId, setAnimationRequestId] = useState(null);
  const canvasContainer = useRef(null);
  useEffect(() => {
    let stopAnimation;
    const loadAnimationCanvas = async () => {
      try {
        const { json, performance } = await thumbnail3d.getThumbnail3dJson(
          targetId,
          getThumbnailJson,
          ThumbnailTypes.Animation
        );
        const { canvas, stopAnimationFrame } = await thumbnail3d.loadObjAndMtlAnimatedAsset(
          targetId,
          canvasContainer.current,
          json,
          onAnimationLoopFinish
        );
        stopAnimation = stopAnimationFrame;

        setIsLoading(false);
        setAnimationRequestId(animationRequestId);
        if (!canvasContainer.current.firstChild) {
          canvasContainer.current.append(canvas);
        } else {
          canvasContainer.current.replaceChild(canvas, canvasContainer.current.firstChild);
        }

        if (onSuccess) onSuccess(performance);
      } catch (err) {
        if (onFailure) onFailure(err);
      }
    };
    loadAnimationCanvas();
    return () => {
      // clean up animation frame after component dismount
      if (typeof stopAnimation === 'function') stopAnimation();
    };
  }, [onFailure, onSuccess, targetId, animationRequestId, getThumbnailJson, onAnimationLoopFinish]);
  return (
    <span ref={canvasContainer} className='thumbnail-animation-container'>
      {isLoading && (
        <div className='thumbnail-loader'>
          <span className={loadingClass} />
        </div>
      )}
    </span>
  );
}

ThumbnailAnimationContainer.defaultProps = {
  getThumbnailJson: null,
  onSuccess: () => {},
  onFailure: () => {},
  onAnimationLoopFinish: () => {},
  loadingClass: 'spinner spinner-default'
};

ThumbnailAnimationContainer.propTypes = {
  targetId: PropTypes.number.isRequired,
  getThumbnailJson: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onAnimationLoopFinish: PropTypes.bool,
  loadingClass: PropTypes.string
};

export default ThumbnailAnimationContainer;
