import { ThumbnailMetrics } from 'Roblox';
import { WebGLJson } from 'THREE';
import { AxiosPromise, httpService } from 'core-utilities';
import metricsService from '../../shared/metricsService';

import {
  Thumbnail3DJsonSuccess,
  Thumbnail3DJsonFail,
  Thumbnail3DJsonResponse,
  Thumbnail3DJsonStats,
  Thumbnail3DJsonUrlResponse,
  DefaultMaxRetry,
  DefaultMaxRetryInterval,
  ThumbnailStates,
  ThumbnailTypes
} from '../constants/thumbnail3dConstant';

import { getAvatarThumbnail3dJsonUrl, getAnimationManifestJsonUrl, getAssetJsonUrl, getUserOutfitJsonUrl} from '../constants/urlConstant';
import thumbnailRenderer from '../util/thumbnail3dRenderer';
import thumbnailAnimatedRenderer from '../util/thumbnailAnimatedRenderer';

const { loadObjAndMtl3D } = thumbnailRenderer;
const { loadObjAndMtlAnimatedAsset } = thumbnailAnimatedRenderer;

const get3DJsonData = (
  jsonRes: Thumbnail3DJsonResponse,
  success: Thumbnail3DJsonSuccess,
  fail: Thumbnail3DJsonFail,
  stats: Thumbnail3DJsonStats
) => {
  const jsonUrlConfig = {
    url: jsonRes.data.imageUrl
  };
  httpService
    .get(jsonUrlConfig)
    .then((jsonUrlRes: Thumbnail3DJsonUrlResponse) => {
      success(jsonUrlRes.data, stats);
    })
    .catch(() => {
      fail('3D Thumbnail failed to load');
    });
};

const getJsonUrlByThumbnailType = (type: ThumbnailTypes, targetId: number) => {
  if (type === ThumbnailTypes.Animation) return getAnimationManifestJsonUrl(targetId);
  if (type === ThumbnailTypes.Asset) return getAssetJsonUrl(targetId);
  if (type === ThumbnailTypes.UserOutfit) return getUserOutfitJsonUrl(targetId);
  if (type === ThumbnailTypes.Avatar) return getAvatarThumbnail3dJsonUrl(targetId);
  throw new Error(`Invalid thumbnail type: ${type}`);
}

const get3DJson = (
  targetId: number,
  type: ThumbnailTypes,
  getJson: () => AxiosPromise<unknown>,
  success: Thumbnail3DJsonSuccess,
  fail: Thumbnail3DJsonFail,
  retries: number,
  retryInterval: number,
  stats: Thumbnail3DJsonStats
) => {
  let requestJson;
  try
  {
    if (getJson === null || getJson === undefined) {
      const urlConfig = { url: getJsonUrlByThumbnailType(type, targetId), withCredentials: true };
      requestJson = httpService.get(urlConfig);
    } else {
      requestJson = getJson();
    }
  }
  catch(e)
  {
    console.log(`error construct get json request, exception: ${e}`);
    throw e;
  }

  requestJson
    .then((jsonRes: Thumbnail3DJsonResponse) => {
      console.log({ jsonRes });
      if (jsonRes.data && jsonRes.data.state === ThumbnailStates.complete) {
        stats.version = jsonRes.data.version;
        get3DJsonData(jsonRes, success, fail, stats);
      } else {
        stats.realRegeneration = false;
        if (retries-- > 0) {
          stats.retriesDone++;
          setTimeout(() => {
            get3DJson(targetId, type, getJson, success, fail, retries, retryInterval, stats);
          }, retryInterval);
        } else {
          fail('3D Thumbnail failed to load');
        }
      }
    })
    .catch(() => {
      fail('3D Thumbnail failed to load');
    });
};

const processRequest = (
  targetId: number,
  type: ThumbnailTypes,
  getJson: () => AxiosPromise<unknown>,
  success: Thumbnail3DJsonSuccess,
  fail: Thumbnail3DJsonFail,
  retries: number,
  retryInterval: number
) => {
  const stats = {
    realRegeneration: false,
    startTime: new Date(),
    retriesDone: 0,
    version: 'TN2'
  };
  get3DJson(targetId, type, getJson, success, fail, retries, retryInterval, stats);
};

const getThumbnail3dJson = (
  targetId = 0,
  getJson: () => AxiosPromise<unknown>,
  type = ThumbnailTypes.Avatar,
  retries: number = DefaultMaxRetry,
  retryInterval: number = DefaultMaxRetryInterval
) => {
  if (!targetId && getJson !== null && getJson !== undefined) {
    return new Promise((resolve, reject) => {
      reject('TargetId or GetJson function can not be empty.');
    });
  }

  return new Promise((resolve, reject) => {
    processRequest(
      targetId,
      type,
      getJson,
      (json: WebGLJson, stats: Thumbnail3DJsonStats) => {
        const finishTime = new Date().getTime();
        const duration = finishTime - stats.startTime.getTime();
        if (ThumbnailMetrics) {
          ThumbnailMetrics.logFinalThumbnailTime(duration);
        }

        if (metricsService) {
          // log load success with retry
          metricsService
            .logMeasurement('ThumbnailLoadDurationWebapp', {
              Status: 'Success',
              ThumbnailType: `${type}_3d`,
              Version: stats.version,
              Value: duration.toString()
            })
            // eslint-disable-next-line no-console
            .catch(console.debug);
          if (stats.retriesDone === 0) {
            // load success without retry
            metricsService
              .logMeasurement('ThumbnailNoRetrySuccessWebapp', {
                ThumbnailType: `${type}_3d`,
                Version: stats.version
              })
              // eslint-disable-next-line no-console
              .catch(console.debug);
          } else {
            // log retry attempts by type
            metricsService
              .logMeasurement('ThumbnailRetryWebapp', {
                ThumbnailType: `${type}_3d`,
                Version: stats.version,
                Value: stats.retriesDone.toString()
              })
              // eslint-disable-next-line no-console
              .catch(console.debug);
          }
        }
        resolve({ json, performance: { duration } });
      },
      (error: string) => {
        if (ThumbnailMetrics) {
          ThumbnailMetrics.logThumbnailTimeout();
        }

        if (metricsService) {
          metricsService
            .logMeasurement('ThumbnailTimeoutWebapp', {
              ThumbnailType: `${type}_3d`,
              Version: 'TN3'
            })
            // eslint-disable-next-line no-console
            .catch(console.debug);
        }
        reject(error);
      },
      retries,
      retryInterval
    );
  });
};

export default {
  getThumbnail3dJson,
  loadObjAndMtl3D,
  loadObjAndMtlAnimatedAsset
};
