import { Endpoints, EnvironmentUrls } from 'Roblox';

const { thumbnailsApi } = EnvironmentUrls;

export const getAvatarThumbnail3dJsonUrl = (userId: number) =>
  Endpoints.getAbsoluteUrl(`${thumbnailsApi}/v1/users/avatar-3d?userId=${userId}`);

export const getAnimationManifestJsonUrl = (assetId: number) =>
  Endpoints.getAbsoluteUrl(`${thumbnailsApi}/v1/asset-thumbnail-animated?assetId=${assetId}`);

export const getAssetJsonUrl = (assetId: number) => 
  Endpoints.getAbsoluteUrl(`${thumbnailsApi}/v1/assets-thumbnail-3d?assetId=${assetId}`);

export const getUserOutfitJsonUrl = (userOutfitId: number) =>
  Endpoints.getAbsoluteUrl(`${thumbnailsApi}/v1/users/outfit-3d?outfitId=${userOutfitId}`);

