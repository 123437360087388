(function () {
  class AnimationLoader {
    getHashUrl(hash) {
      let st = 31;
      for (let ii = 0; ii < hash.length; ii++) {
        st ^= hash[ii].charCodeAt(0);
      }
      return `https://t${(st % 8).toString()}.rbxcdn.com/${hash}`;
    }

    loadWithRetries(xhrLoader, url, onSuccess, onProgress, onError) {
      const retries = 0;
      const maxRetries = 4;
      const retryWait = 5 * 1000;

      const onLoadError = () => {
        if (retries < maxRetries) {
          retries += 1;
          setTimeout(doLoad, retryWait);
        } else {
          onError('Unable to load file', url);
        }
      };

      const doLoad = () => {
        xhrLoader.load(url, onSuccess, onProgress, onLoadError);
      };

      doLoad();
    }

    interpretData(objMtlLoader, data, onComplete) {
      const scope = this;
      const xhrLoader = new THREE.FileLoader(scope.manager);

      function isHash(data) {
        // Assuming hash will be between 32 to 39 characters
        return (data.length >= 32 && data.length) <= 39 && data.indexOf('/') === -1;
      }
      function isUrl(data) {
        return !isHash(data) && data.indexOf('/') > -1 && data.indexOf('\n') === -1;
      }
      function isMtlData(data) {
        return !isHash(data) && !isUrl(data);
      }
      if (isHash(data)) {
        this.loadWithRetries(
          xhrLoader,
          this.getHashUrl(data),
          res => {
            onComplete(objMtlLoader, res);
          },
          () => {},
          () => {}
        );
      } else if (isUrl(data)) {
        this.loadWithRetries(
          xhrLoader,
          data,
          res => {
            onComplete(objMtlLoader, res);
          },
          () => {},
          () => {}
        );
      } else if (isMtlData(data)) {
        onComplete(objMtlLoader, data);
      }
    }

    cleanObjData(data) {
      // hack to get rid of -1.#IND problem with Ice Crown
      return data.replace(new RegExp('-1.#IND', 'g'), '0');
    }

    load(objHash, mtlHash, onLoad) {
      let mtl;
      let obj;
      const mtlLoader = new THREE.MTLLoader();
      const objLoader = new THREE.OBJLoader();
      this.interpretData(this, mtlHash, (objMtlLoader, mtlData) => {
        mtl = mtlLoader.parse(mtlData);
        mtl.preload();
        objMtlLoader.interpretData(objMtlLoader, objHash, (objMtlLoader, objData) => {
          objData = objMtlLoader.cleanObjData(objData);
          obj = objLoader.setMaterials(mtl).parse(objData);
          obj.traverse(child => {
            if (child instanceof THREE.Mesh) {
              if (child.material.name) {
                const submaterial = mtl.create(child.material.name);
                if (submaterial) {
                  child.material = submaterial;
                }
              }
            }
          });
          onLoad(obj, mtl);
        });
      });
    }
  }
  THREE.AnimationLoader = AnimationLoader;
})();
