import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import thumbnail3d from '../../../ts/3d/services/thumbnail3d';
import { ThumbnailTypes } from '../../../ts/3d/constants/thumbnail3dConstant';

function Thumbnail3dContainer({
  targetId,
  getThumbnailJson,
  useDynamicLighting,
  onSuccess,
  onFailure,
  loadingClass,
  type = ThumbnailTypes.Avatar
}) {
  const [isLoading, setIsLoading] = useState(true);
  const canvasContainer = useRef(null);
  useEffect(() => {
    let cleanUp;
    const load3dCanvas = async () => {
      try {
        const { json, performance } = await thumbnail3d.getThumbnail3dJson(
          targetId,
          getThumbnailJson,
          type
        );
        const [canvas, cleanUpObjects] = await thumbnail3d.loadObjAndMtl3D(
          targetId,
          canvasContainer.current.parentElement,
          json,
          useDynamicLighting
        );
        cleanUp = cleanUpObjects;

        setIsLoading(false);
        canvasContainer.current.append(canvas);
        if (onSuccess) onSuccess(performance);
      } catch (err) {
        if (onFailure) onFailure(err);
      }
    };
    load3dCanvas();
    return () => {
      if (typeof cleanUp === 'function') cleanUp();
    };
  }, [getThumbnailJson, type, onFailure, onSuccess, targetId, useDynamicLighting]);
  return (
    <span ref={canvasContainer} id='thumbnail-3d-container'>
      {isLoading && (
        <div className='thumbnail-loader'>
          <span className={loadingClass} />
        </div>
      )}
    </span>
  );
}

Thumbnail3dContainer.defaultProps = {
  useDynamicLighting: false,
  getThumbnailJson: null,
  onSuccess: () => {},
  onFailure: () => {},
  loadingClass: 'spinner spinner-default'
};

Thumbnail3dContainer.propTypes = {
  type: PropTypes.string.isRequired,
  targetId: PropTypes.number.isRequired,
  getThumbnailJson: PropTypes.func,
  useDynamicLighting: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  loadingClass: PropTypes.string
};

export default Thumbnail3dContainer;
