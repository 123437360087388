import angular from 'angular';
import { importFilesUnderPath, templateCacheGenerator } from 'roblox-es6-migration-helper';

// import 3D library
import '../../js/3d/three-r137/three';
import '../../js/3d/three-r137/MTLLoader';
import '../../js/3d/three-r137/OBJLoader';
import '../../js/3d/three-r137/AnimationLoader';
import '../../js/3d/tween';
import '../../js/3d/three-r137/RobloxOrbitControls';

import Thumbnail3d from '../../js/react/thumbnail3d/Thumbnail3dContainer';
import AnimatedThumbnail from '../../js/react/thumbnail3d/ThumbnailAnimationContainer';

// import 3d thumbnail service
import thumbnail3dService from './services/thumbnail3d';
import * as thumbnail3dConstant from './constants/thumbnail3dConstant';

import thumbnails3dModule from '../../js/angular/thumbnails3d/thumbnails3dModule';

window.RobloxThumbnail3d = {
  thumbnail3dService,
  Thumbnail3d,
  AnimatedThumbnail,
  ...thumbnail3dConstant
};

importFilesUnderPath(require.context('../../js/angular/thumbnails3d/components/', true, /\.js$/));
importFilesUnderPath(require.context('../../js/angular/thumbnails3d/controllers/', true, /\.js$/));
importFilesUnderPath(require.context('../../js/angular/thumbnails3d/services/', true, /\.js$/));
importFilesUnderPath(require.context('../../js/angular/thumbnails3d/directives/', true, /\.js$/));

const templateContext = require.context('../../js/angular/thumbnails3d', true, /\.html$/);

templateCacheGenerator(angular, 'thumbnails3dTemplates', templateContext, null);

export default thumbnails3dModule;
